import { ReactElement, useState } from 'react';
import { NumberFormatter } from '../utils/numberFormat';
import BudgetFigure from './BudgetFigure';
import styles from './BudgetFigure.module.css';

export interface BudgetFigureTab {
    tabName: string;
    figureValue: number | string | ReactElement;
}

interface Props {
    figureTabs: BudgetFigureTabs;
    caption: string;
    fadedCaption?: boolean;
    inverted?: boolean;
    white?: boolean;
    formatter?:
        | NumberFormatter
        | ((figure: number | string) => string | ReactElement);
}

export interface BudgetFigureTabs {
    tab1: BudgetFigureTab;
    tab2: BudgetFigureTab;
    tab3: BudgetFigureTab;
    tab4: BudgetFigureTab;
}
export const BudgetFigureWithTabs: React.FC<Props> = ({
    figureTabs,
    caption,
    inverted,
    white,
    formatter,
    fadedCaption,
}) => {
    enum Tabs {
        firstTab = 1,
        secondTab = 2,
        thirdTab = 3,
        fourthTab = 4,
    }
    const [activeTab, setActiveTab] = useState(Tabs.firstTab);

    const getFigureTabValue = (tab: Tabs) => {
        switch (tab) {
            case Tabs.firstTab:
                return figureTabs.tab1.figureValue;
            case Tabs.secondTab:
                return figureTabs?.tab2.figureValue;
            case Tabs.thirdTab:
                return figureTabs?.tab3.figureValue;
            case Tabs.fourthTab:
                return figureTabs?.tab4.figureValue;
            default:
                return 'N/A';
        }
    };

    return (
        <BudgetFigure
            inverted={inverted}
            white={white}
            formatter={formatter}
            figure={
                typeof activeTab === 'string' || typeof activeTab === 'number'
                    ? getFigureTabValue(activeTab)
                    : activeTab
            }
            caption={
                <div className={styles.captionRow}>
                    <div
                        className={[
                            styles.figcaption,
                            fadedCaption ? styles.faded : '',
                        ].join(' ')}
                    >
                        {' '}
                        {caption}
                    </div>
                    <div className={styles.rowWithTabs}>
                        <button
                            onClick={() => setActiveTab(Tabs.firstTab)}
                            className={[
                                styles.tab,
                                activeTab === Tabs.firstTab
                                    ? styles.active
                                    : styles.inactive,
                            ].join(' ')}
                        >
                            {figureTabs.tab1.tabName}
                        </button>
                        <button
                            onClick={() => setActiveTab(Tabs.secondTab)}
                            className={[
                                styles.tab,
                                activeTab === Tabs.secondTab
                                    ? styles.active
                                    : styles.inactive,
                            ].join(' ')}
                        >
                            {figureTabs.tab2.tabName}
                        </button>
                        <button
                            onClick={() => setActiveTab(Tabs.thirdTab)}
                            className={[
                                styles.tab,
                                activeTab === Tabs.thirdTab
                                    ? styles.active
                                    : styles.inactive,
                            ].join(' ')}
                        >
                            {figureTabs.tab3.tabName}
                        </button>
                        <button
                            onClick={() => setActiveTab(Tabs.fourthTab)}
                            className={[
                                styles.tab,
                                activeTab === Tabs.fourthTab
                                    ? styles.active
                                    : styles.inactive,
                            ].join(' ')}
                        >
                            {figureTabs.tab4.tabName}
                        </button>
                    </div>
                </div>
            }
        />
    );
};
