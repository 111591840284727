import { apiClient } from '../apiClient/apiClient';
import moment from 'moment';
import { db } from './db';
import { HistoricVesselVrRate, VesselVrRateDto } from '../apiClient/generated';
import { VesselVrRatesToDisplayTrendGraphData } from '../types/vesselVrRates';

export const fetchAndUpdateVesselVrRates = async () => {
    try {
        const vesselMetadata = await db.vesselMetadata.toArray();
        const vesselCompany = vesselMetadata[0]?.company;
        const fetchDate = moment().format('YYYY-MM');

        if (!vesselCompany) {
            console.error('Vessel company is not available');
            return;
        }

        const vrRatesResponse: VesselVrRateDto = await apiClient.getVesselVr(vesselCompany, fetchDate);

        if (vrRatesResponse) {
            await db.vesselVrRates.clear();
            await db.vesselVrRates.put(vrRatesResponse);
        }

        return vrRatesResponse;
    } catch (error) {
        console.error('Error fetching and updating vessel VR rates:', error);
    }
};

const getLast12Months = (): string[] => {
    const months = moment.monthsShort();
    const currentMonth = moment().month();
    return [...months.slice(currentMonth), ...months.slice(0, currentMonth)];
};

const getLast6Months = (): string[] => {
    const currentMonth = moment().month();
    const startMonth = currentMonth - 6;
    return moment.monthsShort().slice(Math.max(0, startMonth), currentMonth);
};

export const adjustVesselVrRatesToDisplayOnTrendGraph = (
    vrRatesHistory: HistoricVesselVrRate[] | null | undefined,
    filterType: 'last12Months' | 'yearToDate' | 'last6Months'
): VesselVrRatesToDisplayTrendGraphData[] => {
    if (!vrRatesHistory) {
        return [];
    }

    let months: string[];
    if (filterType === 'last12Months') {
        months = getLast12Months();
    } else if (filterType === 'last6Months') {
        months = getLast6Months();
    } else {
        months = moment.monthsShort();
    }

    const currentYear = moment().year();

    return months.map((month) => {
        const vrRateForMonth = vrRatesHistory.find(
            (item) =>
                month.toLowerCase() === moment(item.month).format('MMM').toLowerCase() &&
                (filterType === 'last12Months' || moment(item.month).year() === currentYear)
        );

        return {
            month: month,
            vrRate: vrRateForMonth?.vrRate,
            budgetRate: vrRateForMonth?.budgetRate,
        };
    });
};


export const checkIfTrendDataIsEmpty = (
    trendData: VesselVrRatesToDisplayTrendGraphData[],
) => {
    const isVrRateAvailable = !trendData.find((item) => {
        return item.vrRate !== undefined;
    });
    const isBudgetRateAvailable = !trendData.find((item) => {
        return item.budgetRate !== undefined;
    });
    return isVrRateAvailable && isBudgetRateAvailable;
};
